import './App.css';

import {Helmet} from "react-helmet";
import {Analytics} from "@vercel/analytics/react";

// FIREBASE START
// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCkobmUtuj9YZTvq7dyvyT1yhB44Tj85p4",
    authDomain: "eb-mainpage.firebaseapp.com",
    projectId: "eb-mainpage",
    storageBucket: "eb-mainpage.appspot.com",
    messagingSenderId: "11131818275",
    appId: "1:11131818275:web:dd474531a16083b29cfc96",
    measurementId: "G-LFJSPDR48M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// FIREBASE END

function GoToSiteButton(props) {
    return (
        <div className={'boxButton'} onClick={() => window.location = props.url}>
            <p className={'boxButtonText'}>Go To Website</p>
        </div>
    );
}

function App() {
    return (
        <>
            <Analytics/>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap"
                      rel="stylesheet"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
                    rel="stylesheet"/>
            </Helmet>
            <div className="main">
                <div className={'row'}>
                    <img className={'titleIcon'} src={'/favicon.png'} alt={'logo'}/>
                    <p className={"title"}>EasyBlock.Finance</p>
                </div>
                <p className={'subTitle'}>Decentralization for Everyone</p>
                <div className={window.innerWidth < 960 ? null : 'row'}>
                    <div className={"projectBox"}>
                        <img src={"/favicon.png"} className={"projectBoxIcon"} alt="EasyBackup logo"/>
                        <p className={'boxTitle'}>EasyBackup</p>
                        <p className={'boxDescription'}>Decentralized
                            Backup and Inheritance</p>
                        <GoToSiteButton url={'https://backup.easyblock.finance'}/>
                    </div>

                    <div className={"projectBox"}>
                        <img src={"/mascot.svg"} className={"projectBoxIcon"}
                             style={{width: 120, height: 120, marginBottom: -12}}
                             alt="EasyClub logo"/>
                        <p className={'boxTitle'}>The Easy Club</p>
                        <p className={'boxDescription'}>On-Chain Genesis NFT Collection</p>
                        <GoToSiteButton url={'https://club.easyblock.finance'}/>
                    </div>

                    <div className={"projectBox"}>
                        <img src={"/favicon.png"} className={"projectBoxIcon"} alt="EasyBackup logo"/>
                        <p className={'boxTitle'}>EasyDCA</p>
                        <p className={'boxDescription'}>Decentralized Dollar-Cost-Averaging Protocol</p>
                        <GoToSiteButton url={'https://dca.easyblock.finance'}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
